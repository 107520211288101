<template>
  <div class="full-page d-flex" :class="customClass">
    <div class="max-width">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props : {
      customClass : {
        type     : String,
        required : false,
        default() {
          return 'align-items-center justify-content-center';
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/shared/full-page-content';
</style>